#broadcast-preview {
  max-width: 100% !important;
  opacity: 1;
  /* width: 50%; */
  /* height: 90%; */
  /* position: inherit; */
  position: relative;
}

/* .broadcast-button {
  background-color: green;
  border-color: darkgreen;
  color: white;
  font-size: 20px;
  padding: 10px 60px;
  border-radius: 5px;
  margin: 10px 0px;
  position: absolute;
} */